<template>
    <div class='banner'>
        <div class="ban_item" v-for="(item, index) in bannerList" :key="index" @click="routePage(item)">
            <div class="ban_box" :style="{'background': item.bgaColor}">
                <div :class="item.type == 'special' ? 'core' : 'ban_icon' ">
                    <img class="img" :src="item.icon" alt="">
                </div>
            </div>
            <div class="title">{{item.text}}</div>
        </div>
    </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      bannerList: [
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/corp.png`,
          text: '探索CORE',
          bgaColor: '#FF9580',
          type: 'special'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon1.png`,
          text: '疗愈城邦',
          bgaColor: '#9966FF'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon2.png`,
          text: '一起疗愈',
          bgaColor: '#9966FF'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon3.png`,
          text: '疗愈挑战',
          bgaColor: '#9966FF',
          page: '/adiponectin'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon4.png`,
          text: '训练营',
          bgaColor: '#9966FF'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon5.png`,
          text: '健康锦囊',
          bgaColor: '#9966FF',
          page: '/fillInInfo'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon6.png`,
          text: '个人检测',
          bgaColor: '#9966FF',
          page: '/coreReport'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon7.png`,
          text: '分析报告',
          bgaColor: '#9966FF',
          page: '/ifeelReport'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon8.png`,
          text: '成员管理',
          bgaColor: '#9966FF'
        },
        {
          icon: `${this.$imgDomain}/www/HealthingCore/icon/icon9.png`,
          text: '使用助手',
          bgaColor: '#9966FF'
        }

      ]
    }
  },
  props: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    routePage (item) {
      console.log(item)
      if (item.page) {
        if (item.page == '/ifeelReport') {
          this.$router.push({ path: item.page, query: { id: '21690' } })
          return
        }
        if (item.page == '/coreReport') {
          this.$router.push({ path: item.page, query: { id: '21690' } })
          return
        }
        this.$router.push({ path: item.page })
      }
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 10px;
    .ban_item {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 12px;
        .ban_box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: .55rem;
            height: .55rem;
            border-radius: .3rem;
            overflow: hidden;
            .ban_icon {
                width: 65%;
                height: 65%;
            }
            .core {
                width: 90%;
            }
        }
        .title {
            color: #898989;
            font-size: .16rem;
            margin-top: 7px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
