<template>
  <div class='test'>
    <img class="img" :src="testData.imgUrl" alt="">
    <div class="title">
      <div class="box" v-for="(item, index) in testData.textArr" :key="index"
        @click="getUserInfo(index, item.miniPage, item.type)">
        <span class="text" :style="{ 'color': testData.textColor }">{{ item.text }}</span>
        <span class="line" :style="{ 'background': item.lineColor }"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Dialog } from 'vant';
import wx from '../../util/jweixin1.3.2'
import { GetDetailDataByUserId, GetUserInfo, InsertUserDetail } from '@/api/index'
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])

  },
  data() {
    return {

    }
  },
  props: ['testData'],
  created() {

  },
  mounted() {

  },
  methods: {
    // 获取校验用户信息
    getUserInfo(index, page, type) {
      if (index == 1) {
        GetUserInfo({ userid: this.userid }).then(res => {
          const { data } = res
          if (data.userName) {
            this.checkPhoto(page, type)
          } else {
            this.$dialog.confirm({
              title: '个人信息待完善',
              confirmButtonText: '去完善',
              message: '请先完善个人信息'
            }).then(() => {
              this.$router.push('/updateUserInfo')
            }).catch(() => {

            })
          }
        })
      }
    },
    // 校验时效
    checkPhoto(page, type) {
      const params = {
        userid: this.userid,
        checkReportType: type
      }
      GetDetailDataByUserId(params).then(res => {
        if (res.check30 == true) {
          if (res.check7 == true) {
            Dialog.confirm({
              title: '提示',
              message: '是否重新上传',
            }).then(() => {
              this.miniNavigateTo(page, type)
            }).catch(() => {
              this.InsertUserDetail(type, res.image)
            });
          } else {
            this.miniNavigateTo(page, type)
          }
        } else {
          Dialog.alert({
            message: '30天内禁止点击',
          }).then(() => {

          });
        }
      })
    },// 用户选择取消时回调该接口
    InsertUserDetail(type, imgurl) {
      InsertUserDetail({ userid: this.userid, checkReportType: type, userImage: imgurl }).then(res => {
        if (res.code == 0) {
          alert("接口调用成功")
        }
      })
    },
    // H5 跳转至小程序页面
    miniNavigateTo(page, type) {
      console.log(page, type)
      wx.miniProgram.navigateTo({
        url: `${page}?type=${type}`
      })
    }
  },
  watch: {

  }
}
</script>
<style lang='scss' scoped>
.test {
  width: 100%;
  position: relative;

  .title {
    width: 100%;
    height: .7rem;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99;

    .box {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: .2rem;

      .text {
        font-size: 15px;
      }

      .line {
        width: 23px;
        height: 2px;
        border-radius: 2px;
        margin-top: 5px;
      }
    }
  }
}
</style>
