<template>
    <div class='goodsList'>
        <div v-for="(item, index) in goodsList" :key="index" class="goodsItem">
            <div class="goodsimg">
                <img class="img" :src="item.goodsImg" alt="">
            </div>
            <div class="goodsinfo">
                <div class="title">
                    <div class="hotTip">{{item.hot}}</div>
                    <div class="goodstitle">{{item.title}}</div>
                </div>
                <div class="praise">好评率{{item.praise}}%</div>
                <div class="price">
                    <div class="moneylay">
                        <span>￥</span>
                        <span class="money">{{item.price}}</span>
                    </div>
                    <div class="peoples">{{item.payment}}+人付款</div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            goodsList: [
                {
                    title: '睡前一片安睡一整晚',
                    hot: '舒眠片',
                    praise: '100',
                    price: '321',
                    payment: '9999',
                    goodsImg: `${this.$imgDomain}/www/HealthingCore/goods1.png`
                },
                {
                    title: '睡前一片安睡一整晚',
                    hot: '舒眠片',
                    praise: '100',
                    price: '887',
                    payment: '9999',
                    goodsImg: `${this.$imgDomain}/www/HealthingCore/goods3.png`
                },
                {
                    title: '睡前一片安睡一整晚',
                    hot: '舒眠片',
                    praise: '100',
                    price: '267',
                    payment: '995',
                    goodsImg: `${this.$imgDomain}/www/HealthingCore/goods2.png`
                },
                {
                    title: '睡前一片安睡一整晚',
                    hot: '舒眠片',
                    praise: '100',
                    price: '158',
                    payment: '999',
                    goodsImg: `${this.$imgDomain}/www/HealthingCore/goods1.png`
                },
            ]
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.goodsList {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .goodsItem {
        width: 48%;
        height: 3rem;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: .18rem;
        margin-right: 4%;
        .goodsimg {
            width: 100%;
            height: 1.8rem;
        }
        .goodsinfo {
            background: #fff;
            padding: 0.15rem;
            .title {
                display: flex;
                align-items: center;
                .hotTip {
                    width: 0.6rem;
                    height: 0.25rem;
                    text-align: center;
                    line-height: 0.25rem;
                    background: #FF9580;
                    color: #fff;
                    font-size: .17rem;
                    border-radius: 3px;
                    margin-right: 5px;
                }
                .goodstitle{
                    width: auto;
                    color: #898989;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .praise {
                color: #FF9580;
                margin: 5px 0;
            }
            .price {
                display: flex;
                align-items: center;
                .moneylay {
                    color: #FF9580;
                    .money {
                        font-size: 0.26rem;
                    }
                }
            }
            .peoples {
                color: #898989;
                margin-left: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .goodsItem:nth-child(2n) {
        margin-right: 0;
    }
}
</style>