<template>
    <div class="silkbag">
        <div class="silk_img">
            <img class="img" :src="silkImg" alt="">
        </div>
        <div class="textInfo">
            <div class="textItem" v-for="(item, index) in silkText" :key="index">
                <div class="title">{{item.title}}</div>
                <div class="subtitle">{{item.subtitle}}</div>
                <div class="tips">{{item.tips}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            silkText: [
                {
                    title: '3000+万家庭的选择',
                    subtitle: '24小时监测，守护家人健康',
                    tips: '全新智能化时代的...'
                },
                {
                    title: '个性化营养定制',
                    subtitle: '精准营养，修复人体代谢机能',
                    tips: '让健康来的更简单...'
                },
                {
                    title: '阳光下的健康生活',
                    subtitle: '有机食物对大健康时代的影响',
                    tips: '天地润泽，万物新生...'
                },
            ],
            
        };
    },
    props: ['silkImg'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.silkbag {
    width: 100%;
    background: #fff;
    padding: 22px 0 22px 18px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    .silk_img {
        width: 100px;
        height: 184px;
        border-radius: 20px;
        overflow: hidden;
        margin-right: .2rem;
    }
    .textInfo {
        width: calc(100% - 1.6rem);
        color: #898989;
        .textItem {
            width: 100%;
            margin-bottom: .1rem;
            border-bottom: 1px solid #9FA0A0;
            .title,
            .subtitle,
            .tips {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .title {
                color: #6a6b6b;
                font-size: .22rem;
            }
            .subtitle {
                font-size: .18rem;
                margin-bottom: 5px;
            }
            .tips {
                margin-bottom: 3px;
            }
            
        }
        .textItem:last-child {
            margin: 0;
            border: none;
        }
    }
}
</style>