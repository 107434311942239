<template>
     <div :class="styleClass">
        <div class="personal_info">
            <div class="head_img">
                <img class="img" :src="headPortrait" alt="">
            </div>
            <div class="name_star">
                <div class="name">{{personalInfo.userName}}</div>
                <!-- 评星 -->
                <star notlit="https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/notlit.png" Lighting="https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/ligh.png"></star>
            </div>
        </div>
        <div class="date" v-if="personalInfo.userVipEndTime">
            <div class="tips">
                <div class="tip_img">
                    <div class="crown">
                        <img class="img" :src="personalInfo.crownUrl" alt="">
                    </div>
                    <div class="vip_img">
                        <img class="img" src="https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/VIP.png" alt="">
                    </div>
                </div>

                <div class="text">截止有效期</div>
            </div>
            <div class="time">
                <div class="year">
                    <span>Date {{personalInfo.userVipEndTime[0]}}</span>
                </div>
                <div class="specific" style="color: #ff9580;">{{personalInfo.userVipEndTime[1]}}.{{personalInfo.userVipEndTime[2]}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import star from './star.vue'
export default {
  components: {
    star
  },
  data () {
    return {
      headPortrait: `${this.$imgDomain}/www/HealthingCore/head.png`
    }
  },
  props: ['personalInfo'],
  created () {
  },
  mounted () {

  },
  methods: {

  },
  watch: {

  },
  computed: {
    styleClass () {
      if (this.personalInfo.type == 'core') {
        return 'info_card core'
      } else if (this.personalInfo.type == 'love') {
        return 'info_card love'
      } else if (this.personalInfo.type == 'filialPiety') {
        return 'info_card filialPiety'
      } else {
        return 'info_card'
      }
    }

  }
}
</script>
<style lang='scss' scoped>
.info_card {
    width: 85%;
    height: .7rem;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 99;
    background: #9966ff;
    .personal_info {
        display: flex;
        align-items: center;
        .head_img {
            width: .5rem;
            height: .5rem;
            border-radius: .25rem;
            overflow: hidden;
            margin-right: 10px;
        }
        .name_star {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 10px 0;
            .name {
                font-size: .18rem;
            }

        }
    }
    .date {
        display: flex;
        .tips {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            padding: 10px 0;
            .tip_img {
                display: flex;
                align-items: flex-end;
                .crown {
                    width: .23rem;
                    margin-right: 5px;
                }
                .vip_img {
                    width: .6rem;
                }
            }

            .text {
                font-size: .14rem;
                opacity: 0.5;
            }
        }
        .time {
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 10px;
            margin-left: 10px;

            .specific {
                font-size: .28rem;
                line-height: .22rem;
                margin-top: 3px;
            }
        }
    }
}
.core {

    color: #FFFFFF;
    .text {
        color: #CBB2FE;
    }
    .time {
        border-left: 1px solid #B39FFC;
        .specific {
            color: #ff9580;
        }
    }
}
.love {
    background: #FFE164;
    color: #99873C;
    .text {
        color: #C3AC4C;
    }
    .time {
        border-left: 1px solid #FFE681;
        .specific {
            color: #ff9580;
        }
    }
}

.filialPiety {
    background: #F59D5A;
    color: #FFF;
    .text {
        opacity: 0.5;
    }
    .name {
        opacity: 0.5;
    }
    .time {
        border-left: 1px solid #FACEAD;
        .year {
            opacity: 0.5;
        }
        .specific {
            color: #fff;
        }
    }
}
</style>
