<template>
    <div class="star">
        <div class="star_box" v-for="(item, index) in review" :key="index">
            <img class="img" :src="item.url" alt="">
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            review: [
                {
                    url: this.Lighting
                },
                {
                    url: this.Lighting
                },
                {
                    url: this.Lighting
                },
                {
                    url: this.Lighting
                },
                {
                    url: this.notlit
                }
            ],
        };
    },
    props: ['notlit', 'Lighting'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.star {
    display: flex;
    .star_box {
        width: 7px;
        height: 7px;
        margin-right: 4px;                       
    }
}
</style>