<template>
    <div class="jt_swiper">
        <swiper :options="swiperOptions" ref="jt_swiper">
            <swiper-slide v-for="(item, index) in swiperItem" :key="index">
                <div class="swiper_img">
                    <img class="img" :src="item.url" alt="">
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                loop: false,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: false
                }
            },
        };
    },
    props: ["swiperItem"],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.jt_swiper {
    height: 195px;
    width: 100%;
    margin-top: 20px;
    position: relative;
    /deep/ .swiper-container {
        height: 100%;
    }
    .swiper_img {
        width: 100%;
        height: 175px;
        border-radius: 10px;
        overflow: hidden;
    }
    /deep/ .swiper-pagination {
        width: 100%;
        bottom: 0;
    }
    /deep/ .swiper-pagination-bullet {
        width: 22px;
        height: 2px;
        border-radius: 2px;
        margin-right: 5px;
    }
    /deep/ .swiper-pagination-bullet-active {
        background: #FF9580
    }
}
</style>